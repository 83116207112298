<template>
    <div>
        <v-row>
            <v-col md="12">
                <v-row>
                    <v-col sm="12" cols="12">
                        <v-card class="border-radius-xl card-shadow">
                            <div class="px-6 py-6 text-center">
                                <h2 class="mb-0 font-weight-bolder text-typo text-h2">累積年終獎金</h2>
                                <h1 class="text-gradient text-primary font-weight-bolder text-h1">
                                    <span>${{bonus_year}}</span>
                                </h1>
                            </div>
                        </v-card>
                    </v-col>
                    <v-col sm="6" cols="12">
                        <v-card class="mb-6 card-shadow border-radius-xl py-4">
                            <v-row no-gutters class="px-4">
                                <v-col sm="4">
                                    <v-avatar color="bg-gradient-default border-radius-xl mt-n8" class="shadow-dark" height="64" width="64">
                                        <v-icon class="material-icons-round text-white" size="24">brightness_4</v-icon>
                                    </v-avatar>
                                </v-col>
                                <v-col sm="8" class="text-end">
                                    <p class="
                        text-sm
                        mb-0
                        text-capitalize text-body
                        font-weight-light
                      ">
                                        個人積分
                                    </p>
                                    <h4 class="text-h4 text-typo font-weight-bolder mb-0">
                                        {{point_member}}
                                    </h4>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                    <v-col sm="6" cols="12">
                        <v-card class="mb-6 card-shadow border-radius-xl py-4">
                            <v-row no-gutters class="px-4">
                                <v-col sm="4">
                                    <v-avatar color="bg-gradient-info border-radius-xl mt-n8" class="shadow-info" height="64" width="64">
                                        <v-icon class="material-icons-round text-white" size="24">radio_button_checked</v-icon>
                                    </v-avatar>
                                </v-col>
                                <v-col sm="8" class="text-end">
                                    <p class="
                        text-sm
                        mb-0
                        text-capitalize text-body
                        font-weight-light
                      ">
                                        組織積分
                                    </p>
                                    <h4 class="text-h4 text-typo font-weight-bolder mb-0">
                                        {{point}}
                                    </h4>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                    <!-- <v-col sm="4" cols="12">
                        <v-card class="mb-6 card-shadow border-radius-xl py-4">
                            <v-row no-gutters class="px-4">
                                <v-col sm="4">
                                    <v-avatar color="bg-gradient-primary border-radius-xl mt-n8" class="shadow-primary" height="64" width="64">
                                        <v-icon class="material-icons-round text-white" size="24">shield_moon</v-icon>
                                    </v-avatar>
                                </v-col>
                                <v-col sm="8" class="text-end">
                                    <p class="
                        text-sm
                        mb-0
                        text-capitalize text-body
                        font-weight-light
                      ">
                                        總積分
                                    </p>
                                    <h4 class="text-h4 text-typo font-weight-bolder mb-0">
                                        {{point}}
                                    </h4>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col> -->
                    <v-col sm="4" cols="12">
                        <v-card class="mb-6 card-shadow border-radius-xl py-4">
                            <v-row no-gutters class="px-4">
                                <v-col sm="4">
                                    <v-avatar color="bg-gradient-default border-radius-xl mt-n8" class="shadow-dark" height="64" width="64">
                                        <v-icon class="material-icons-round text-white" size="24">monetization_on</v-icon>
                                    </v-avatar>
                                </v-col>
                                <v-col sm="8" class="text-end">
                                    <p class="
                        text-sm
                        mb-0
                        text-capitalize text-body
                        font-weight-light
                      ">
                                        個人獎金
                                    </p>
                                    <h4 class="text-h4 text-typo font-weight-bolder mb-0">
                                        ${{bonus_self}}
                                    </h4>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                    <v-col sm="4" cols="12">
                        <v-card class="mb-6 card-shadow border-radius-xl py-4">
                            <v-row no-gutters class="px-4">
                                <v-col sm="4">
                                    <v-avatar color="bg-gradient-info border-radius-xl mt-n8" class="shadow-info" height="64" width="64">
                                        <v-icon class="material-icons-round text-white" size="24">monetization_on</v-icon>
                                    </v-avatar>
                                </v-col>
                                <v-col sm="8" class="text-end">
                                    <p class="
                        text-sm
                        mb-0
                        text-capitalize text-body
                        font-weight-light
                      ">
                                        組織獎金
                                    </p>
                                    <h4 class="text-h4 text-typo font-weight-bolder mb-0">
                                        ${{bonus_group}}
                                    </h4>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                    <v-col sm="4" cols="12">
                        <v-card class="mb-6 card-shadow border-radius-xl py-4">
                            <v-row no-gutters class="px-4">
                                <v-col sm="4">
                                    <v-avatar color="bg-gradient-primary border-radius-xl mt-n8" class="shadow-primary" height="64" width="64">
                                        <v-icon class="material-icons-round text-white" size="24">monetization_on</v-icon>
                                    </v-avatar>
                                </v-col>
                                <v-col sm="8" class="text-end">
                                    <p class="
                        text-sm
                        mb-0
                        text-capitalize text-body
                        font-weight-light
                      ">
                                        本月總獎金
                                    </p>
                                    <h4 class="text-h4 text-typo font-weight-bolder mb-0">
                                        ${{bonus_total}}
                                    </h4>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                    <v-col sm="3" cols="12">
                        <v-card class="mb-6 card-shadow border-radius-xl py-4">
                            <v-row no-gutters class="px-4">
                                <v-col sm="4">
                                    <v-avatar color="bg-gradient-info border-radius-xl mt-n8" class="shadow-info" height="64" width="64">
                                        <v-icon class="material-icons-round text-white" size="24">payment</v-icon>
                                    </v-avatar>
                                </v-col>
                                <v-col sm="8" class="text-end">
                                    <p class="
                        text-sm
                        mb-0
                        text-capitalize text-body
                        font-weight-light
                      ">
                                        所得代扣繳
                                    </p>
                                    <h4 class="text-h4 text-typo font-weight-bolder mb-0">
                                        ${{tax_withheld}}
                                    </h4>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                    <v-col sm="3" cols="12">
                        <v-card class="mb-6 card-shadow border-radius-xl py-4">
                            <v-row no-gutters class="px-4">
                                <v-col sm="4">
                                    <v-avatar color="bg-gradient-default border-radius-xl mt-n8" class="shadow-dark" height="64" width="64">
                                        <v-icon class="material-icons-round text-white" size="24">payments</v-icon>
                                    </v-avatar>
                                </v-col>
                                <v-col sm="8" class="text-end">
                                    <p class="
                        text-sm
                        mb-0
                        text-capitalize text-body
                        font-weight-light
                      ">
                                        二代健保
                                    </p>
                                    <h4 class="text-h4 text-typo font-weight-bolder mb-0">
                                        ${{second_nhi}}
                                    </h4>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                    <v-col sm="3" cols="12">
                        <v-card class="mb-6 card-shadow border-radius-xl py-4">
                            <v-row no-gutters class="px-4">
                                <v-col sm="4">
                                    <v-avatar color="bg-gradient-default border-radius-xl mt-n8" class="shadow-dark" height="64" width="64">
                                        <v-icon class="material-icons-round text-white" size="24">payments</v-icon>
                                    </v-avatar>
                                </v-col>
                                <v-col sm="8" class="text-end">
                                    <p class="
                        text-sm
                        mb-0
                        text-capitalize text-body
                        font-weight-light
                      ">
                                        轉帳手續費
                                    </p>
                                    <h4 class="text-h4 text-typo font-weight-bolder mb-0">
                                        ${{fee}}
                                    </h4>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                    <v-col sm="3" cols="12">
                        <v-card class="mb-6 card-shadow border-radius-xl py-4">
                            <v-row no-gutters class="px-4">
                                <v-col sm="4">
                                    <v-avatar color="bg-gradient-primary border-radius-xl mt-n8" class="shadow-primary" height="64" width="64">
                                        <v-icon class="material-icons-round text-white" size="24">price_check</v-icon>
                                    </v-avatar>
                                </v-col>
                                <v-col sm="8" class="text-end">
                                    <p class="
                        text-sm
                        mb-0
                        text-capitalize text-body
                        font-weight-light
                      ">
                                        實際提撥
                                    </p>
                                    <h4 class="text-h4 text-typo font-weight-bolder mb-0">
                                        ${{bonus_real}}
                                    </h4>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-card class="card-shadow border-radius-xl">
            <!--<div class="card-header-padding">
        <h5 class="font-weight-bold text-h5 text-typo mb-0">
          Datatable Search
        </h5>
        <p class="text-sm text-body font-weight-light mb-0">
          Features include sorting, searching, pagination, content-editing, and
          row selection.
        </p>
      </div>-->
            <v-card-text class="px-0 py-0">
                <v-data-table :headers="headers" :items="bonusData" :search="search" class="table" :page.sync="page" hide-default-footer fixed-header @page-count="pageCount = $event" :items-per-page="itemsPerPage" mobile-breakpoint="0" @click:row="rowClick">
                    <template v-slot:top>
                        <v-toolbar flat height="80">
                            <v-row>
                                <v-col cols="12" md="5">
                                    <v-text-field hide-details class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                    " dense flat filled solo height="43" v-model="search" placeholder="搜尋">
                                        <template slot="prepend-inner">
                                            <v-icon color="#adb5bd" size="16" class="material-icons-round mt-n2">search</v-icon>
                                        </template>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-toolbar>
                    </template>
                    <template v-for="header in headers.filter((header) =>
                header.hasOwnProperty('formatter')
              )" v-slot:[`item.${header.value}`]="{ header, value }">
                        {{ header.formatter(value) }}
                    </template>
                    <template v-slot:item.yearMonth="{ item }">
                        <div class="d-flex align-center ms-2">
                            <span class="text-sm font-weight-normal text-body">
                                <u class="v-icon v-icon--link">{{ item.yearMonth }}</u>
                            </span>
                        </div>
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <v-btn @click="editItem(item)" icon elevation="0" :ripple="false" height="28" min-width="36" width="36" class="btn-ls me-2 my-2 rounded-sm" color="#67748e">
                            <v-icon size="14" class="material-icons-round">construction</v-icon>
                        </v-btn>
                        <v-btn @click="deleteItem(item)" icon elevation="0" :ripple="false" height="28" min-width="36" width="36" class="btn-ls me-2 my-2 rounded-sm" color="#67748e">
                            <v-icon size="14" class="material-icons-round">close</v-icon>
                        </v-btn>
                    </template>
                </v-data-table>
            </v-card-text>
            <v-card-actions class="card-padding">
                <v-row>
                    <v-col cols="6" lg="3" class="d-flex align-center">
                        <span class="text-body me-3 text-sm">每頁項目：</span>
                        <v-text-field hide-details type="number" outlined min="-1" max="15" background-color="rgba(255,255,255,.9)" color="rgba(0,0,0,.6)" light :value="itemsPerPage" @input="itemsPerPage = parseInt($event, 10)" placeholder="Items per page" class="
                font-size-input
                placeholder-lighter
                text-color-light
                input-alternative input-focused-alternative input-icon
              ">
                        </v-text-field>
                    </v-col>
                    <v-col cols="6" class="ml-auto d-flex justify-end">
                        <v-pagination prev-icon="fa fa-angle-left" next-icon="fa fa-angle-right" class="pagination" color="#D81B60" v-model="page" :length="pageCount" circle></v-pagination>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </div>
</template>
<script>
import bonusData from "./BonusData";
import Cookie from 'js-cookie';
import axios from 'axios';

let config = {
    headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
        "Content-Type": "application/json;",
    }
}

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

export default {
    name: "paginated-tables",
    data() {
        return {
            dateStart: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            dateEnd: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            startDate: false,
            endDate: false,
            page: 1,
            pageCount: 0,
            itemsPerPage: 20,
            page: 20,
            search: "",
            pageCount: 1,
            dialog: false,
            dialogDelete: false,
            bonusData: [],
            search: "",
            editedIndex: -1,
            bonus_group: 0,
            bonus_real: 0,
            bonus_self: 0,
            bonus_year: 0,
            fee: 0,
            point: 0,
            point_member: 0,
            point_group: 0,
            tax_withheld: 0,
            second_nhi: 0,
            bonus_total: 0,
            recordBonusUserId: 1,
            editedItem: {
                name: "",
                email: "",
                age: "",
                salary: "",
            },
            defaultItem: {
                name: "",
                email: "",
                age: "",
                salary: "",
            },
            headers: [{
                    text: "時間",
                    align: "start",
                    cellClass: "border-bottom",
                    sortable: false,
                    value: "yearMonth",
                    class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
                },
                {
                    text: "位階",
                    value: "level",
                    class: "text-secondary font-weight-bolder opacity-7",
                },
                {
                    text: "合格狀態",
                    value: "passStatus",
                    class: "text-secondary font-weight-bolder opacity-7",
                },
                {
                    text: "個人獎金",
                    value: "bonus",
                    formatter: this.numFormat,
                    class: "text-secondary font-weight-bolder opacity-7",
                },
                {
                    text: "組織獎金",
                    value: "bonusGroup",
                    formatter: this.numFormat,
                    class: "text-secondary font-weight-bolder opacity-7",
                },
                {
                    text: "綜所稅扣繳",
                    value: "individualIncomeTax",
                    formatter: this.numFormat,
                    class: "text-secondary font-weight-bolder opacity-7",
                },

            ],
        };
    },
    mounted() {
        const token = Cookie.get('auth')

        console.log(token)
        console.log(typeof token)
        console.log((token === 'null') || (typeof(token) === "undefined"))
        if ((token === 'null') || (typeof(token) === "undefined")) {
            this.$router.push('/pages/authentication/signup/login');
        }

        const tokenParts = token.split('.')
        console.log(tokenParts)
        const body = JSON.parse(atob(tokenParts[1]))
        console.log(body)
        console.log(body.sub)

        this.userId = body.sub

        let s = this.$route.query.s
        console.log(typeof(s))
        if (s === undefined) {

            this.recordBonusUserId = body.sub


        } else {
            let parentIdStr = s.split('u');
            let parentIdStrLength = parentIdStr.length;
            console.log(parentIdStrLength)
            console.log(parentIdStr[1])
            this.recordBonusUserId = parentIdStr[1]
        }

        let data = {
            yearMonth: "",
        };

        axios.get('/v1/user/' + this.recordBonusUserId.toString() + '/record-bonus', { params: data }, this.config)
            .then(response => {
                this.repsData = response.data;
                console.log(this.repsData)

                this.bonus_group = this.repsData.record_bonus.bonus_group.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                this.bonus_real = this.repsData.record_bonus.bonus_real.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                this.bonus_self = this.repsData.record_bonus.bonus_self.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                this.bonus_year = this.repsData.record_bonus.bonus_year.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                this.fee = this.repsData.record_bonus.fee
                this.point = this.repsData.record_bonus.point.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                this.point_member = this.repsData.record_bonus.point_member.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                this.point_group = (this.repsData.record_bonus.point - this.repsData.record_bonus.point_member).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                this.tax_withheld = this.repsData.record_bonus.tax_withheld.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                this.second_nhi = this.repsData.record_bonus.second_nhi.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                this.bonus_total = (this.repsData.record_bonus.bonus_group + this.repsData.record_bonus.bonus_self).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

            })
            .catch(error => { // 请求失败处理
                console.log(error);
            });


        axios.get('/v1/bonus/' + this.recordBonusUserId.toString(), this.config)
            .then(response => {
                this.repsData = response.data;
                console.log(this.repsData)

                console.log(this.repsData.total)

                this.bonusData = new Array(this.repsData.total)

                for (let i = 0; i < this.repsData.total; i++) {
                    this.bonusData[i] = new Object();
                    this.bonusData[i].yearMonth = this.repsData.list[i].year_month;
                    this.bonusData[i].level = this.repsData.list[i].level;
                    this.bonusData[i].passStatus = this.repsData.list[i].pass_status;
                    if (this.repsData.list[i].pass_status === 1) {
                        this.bonusData[i].passStatus = "合格"
                    } else {
                        this.bonusData[i].passStatus = "尚未合格"
                    }

                    this.bonusData[i].bonus = this.repsData.list[i].bonus_self;
                    this.bonusData[i].bonusGroup = this.repsData.list[i].bonus_group;
                    this.bonusData[i].individualIncomeTax = this.repsData.list[i].tax_withheld;

                }

                console.log(this.bonusData)

            })
            .catch(error => { // 请求失败处理
                console.log(error);
            });

    },
    methods: {
        numFormat(value) {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        },

        editItem(item) {
            this.editedIndex = this.bonusData.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialog = true;
        },

        deleteItem(item) {
            this.editedIndex = this.bonusData.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogDelete = true;
        },

        deleteItemConfirm() {
            this.bonusData.splice(this.editedIndex, 1);
            this.closeDelete();
        },

        close() {
            this.dialog = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },

        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },

        save() {
            if (this.editedIndex > -1) {
                Object.assign(this.bonusData[this.editedIndex], this.editedItem);
            } else {
                this.bonusData.push(this.editedItem);
            }
            this.close();
        },
        rowClick(item, row) {
            console.log("item")
            console.log(item)
            console.log("row")
            console.log(row.item.yearMonth)

            this.$emit('update-row', row);

            let data = {
                yearMonth: row.item.yearMonth,
            };

            axios.get('/v1/user/' + this.recordBonusUserId.toString() + '/record-bonus', { params: data }, this.config)
                .then(response => {
                    this.repsData = response.data;
                    console.log(this.repsData)

                    this.bonus_group = this.repsData.record_bonus.bonus_group.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                    this.bonus_real = this.repsData.record_bonus.bonus_real.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                    this.bonus_self = this.repsData.record_bonus.bonus_self.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                    this.bonus_year = this.repsData.record_bonus.bonus_year.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                    this.fee = this.repsData.record_bonus.fee
                    this.point = this.repsData.record_bonus.point.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                    this.point_member = this.repsData.record_bonus.point_member.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                    this.tax_withheld = this.repsData.record_bonus.tax_withheld.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                    this.second_nhi = this.repsData.record_bonus.second_nhi.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                    this.bonus_total = (this.repsData.record_bonus.bonus_group + this.repsData.record_bonus.bonus_self).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

                })
                .catch(error => { // 请求失败处理
                    console.log(error);
                });

        },
    },
    watch: {
        dialog(val) {
            val || this.close();
        },
        dialogDelete(val) {
            val || this.closeDelete();
        },
    },

    computed: {
        formTitle() {
            return this.editedIndex === -1 ? "New Item" : "Edit Item";
        },
        pages() {
            return this.pagination.rowsPerPage ?
                Math.ceil(this.items.length / this.pagination.rowsPerPage) :
                0;
        },
    },
};
</script>