<template>
    <v-card class="card-shadow border-radius-xl mt-6" id="change">
        <div class="px-6 py-6">
            <h5 class="text-h5 font-weight-bold text-typo">變更密碼</h5>
        </div>
        <div class="px-6 pb-6 pt-0">
            <v-row>
                <v-col cols="12">
                    <!--<v-text-field v-model="current_pwd" label="目前密碼" color="#e91e63" outlined dense class="font-size-input input-style py-0"></v-text-field>-->
                    <v-text-field v-model="new_pwd" label="新密碼" color="#e91e63" outlined dense type="password" class="font-size-input input-style py-0"></v-text-field>
                    <v-text-field v-model="confirm_pwd" label="確認新密碼" color="#e91e63" outlined dense type="password" class="font-size-input input-style py-0"></v-text-field>
                </v-col>
            </v-row>
            <h5 class="mt-5 mb-2 text-h5 font-weight-bold text-typo">
                密碼要求
            </h5>
            <p class="text-muted font-weight-light mb-2">
                請依據下列建議設定密碼：
            </p>
            <div class="d-sm-flex">
                <ul class="text-muted ps-6 mb-0">
                    <li>
                        <span class="text-sm">至少 8 個字元</span>
                    </li>
                </ul>
                <v-btn color="#cb0c9f" class="
            font-weight-bolder
            btn-default
            bg-gradient-default
            py-4
            px-8
            ms-auto
            mt-sm-auto mt-4
          " small @click="showUpdateSuccessAlert">
                    更新密碼
                </v-btn>
            </div>
        </div>
    </v-card>
</template>
<script>
import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";
import Cookie from 'js-cookie';
import axios from 'axios';

Vue.use(VueSweetalert2);

let config = {
    headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
        "Content-Type": "application/json;",
    }
}

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

export default {
    name: "change-password",
    data() {
        return {
            current_pwd: "",
            new_pwd: "",
            confirm_pwd: "",
            email: "",
        };
    },
    mounted() {
        const token = Cookie.get('auth')

        const tokenParts = token.split('.')
        console.log(tokenParts)
        const body = JSON.parse(atob(tokenParts[1]))
        console.log(body)
        console.log(body.sub)

        this.userId = body.sub

        axios.get('/v1/user/' + this.userId.toString(), this.config)
            .then(response => {
                this.repsData = response.data;
                console.log(this.repsData)

                this.name = this.repsData.name
                this.address = this.repsData.address
                //this.gender = [this.repsData.gender]
                this.phone = this.repsData.phone
                this.email = this.repsData.email
                this.role = this.repsData.role
                this.due_date = this.repsData.due_date
                this.birthday = this.repsData.birthday
                this.bank_code = this.repsData.bank_code
                this.bank_account = this.repsData.bank_account
                this.identity = this.repsData.identity

            })
            .catch(error => { // 请求失败处理
                console.log(error);
            });



    },
    watch: {
        confirmPw: function() {
            if ((this.new_pwd.length === this.confirm_pwd.length) && (this.new_pwd === this.confirm_pwd)) {
                this.confirmPwdText = "符合設定新密碼"
                this.isConfirmPwdText = true
            } else {
                this.confirmPwdText = "不符合設定新密碼"
                this.isConfirmPwdText = false
            }
        },


    },
    methods: {
        showUpdateSuccessAlert() {
            if ((this.new_pwd.length !== 0) && (this.confirm_pwd.length !== 0)) {
                if ((this.new_pwd.length !== this.confirm_pwd.length) && (this.new_pwd !== this.confirm_pwd)) {
                    this.$swal("", "新密碼與確認新密碼不一致。", "error");

                } else {
                    console.log("this.email")
                    console.log(this.email)

                    axios.put('/v1/member/' + this.userId.toString(), {
                            name: this.name,
                            gender: "",
                            phone: "",
                            email: this.email,
                            role: "",
                            due_date: "",
                            birthday: "",
                            address: "",
                            bank_code: "",
                            bank_account: "",
                            identity: "",
                            password: this.new_pwd
                        }, this.config)
                        .then(response => {
                            console.log(response)
                            this.repsMessage = response.data.message
                            const errorCode = response.data.code
                            console.log(this.repsMessage)
                            console.log(errorCode)
                            if (errorCode === 0) {
                                this.$swal("", "成功變更密碼。", "success");
                            } else {
                                this.$swal("", this.repsMessage, "error");
                            }

                        })
                        .catch(function(error) { // 请求失败处理
                            console.log(error);
                        });
                }
            }

        },
    },
};
</script>