<template>
    <v-container fluid class="py-6">
        <v-row>
            <!--<v-col lg="4" md="6" cols="12" class="position-relative">
        <v-card class="h-100">
          <div class="pt-4">
            <h6 class="mb-0 text-h6 text-typo">Platform Settings</h6>
          </div>
          <div class="py-4">
            <h6 class="text-uppercase text-body text-xs font-weight-bolder">
              Account
            </h6>
            <v-list>
              <v-list-item-group class="border-radius-sm">
                <v-list-item
                  :ripple="false"
                  v-for="setting in accountSettings"
                  :key="setting.text"
                  class="pe-2 ps-0 border-radius-sm mb-3"
                >
                  <v-switch
                    :ripple="false"
                    class="d-inline-flex mt-0 pt-0 switch ms-auto"
                    v-model="setting.switchState"
                    hide-details
                  ></v-switch>
                  <v-list-item-content class="py-0">
                    <div class="ms-4 text-body text-sm">
                      {{ setting.text }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
            <h6
              class="text-uppercase text-body text-xs font-weight-bolder mt-4"
            >
              Application
            </h6>
            <v-list>
              <v-list-item-group class="border-radius-sm">
                <v-list-item
                  :ripple="false"
                  v-for="setting in applicationSettings"
                  :key="setting.text"
                  class="px-0 border-radius-sm mb-3"
                >
                  <v-switch
                    :ripple="false"
                    class="d-inline-flex mt-0 pt-0 switch ms-auto"
                    v-model="setting.switchState"
                    hide-details
                  ></v-switch>
                  <v-list-item-content class="py-0">
                    <div class="ms-4 text-body text-sm">
                      {{ setting.text }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </div>
        </v-card>
        <hr class="vertical dark" />
      </v-col>-->
            <v-col lg="5" md="6" cols="12" class="position-relative">
                <v-card class="h-100">
                    <div class="px-4 pt-4">
                        <h6 class="mb-0 text-h6 text-typo">個人資訊</h6>
                    </div>
                    <div class="px-4 py-4">
                        <!--<p class="text-sm font-weight-light text-body">
              Hi, I’m Alec Thompson, Decisions: If you can’t decide, the answer
              is no. If two equally difficult paths, choose the one more painful
              in the short term (pain avoidance is creating an illusion of
              equality).
            </p>
            <hr class="horizontal dark mt-6 mb-3" />-->
                        <v-list>
                            <v-list-item-group class="border-radius-sm">
                                <v-list-item :ripple="false" class="px-0 border-radius-sm">
                                    <v-list-item-content v-model="phone" class="py-0">
                                        <div class="text-body text-sm">
                                            <strong class="text-dark">行動電話：</strong>
                                            {{phone}}&nbsp;
                                            <span v-if="!phone_pass_status" class="text-red  font-weight-bold ms-sm-2">
                                                (未驗證)
                                            </span>
                                            <span v-if="phone_pass_status" class="text-green font-weight-bold ms-sm-2">
                                                (已驗證)
                                            </span>
                                            <v-btn v-if="!phone_pass_status" class="
            font-weight-bolder
            btn-default
            bg-gradient-default
            ms-auto
            mt-sm-auto mt-4
          " small @click="verifyPhone">
                                                驗證
                                            </v-btn>
                                            <!--<v-icon v-if="phone_pass_status" color="#5FAF58" class="mt-n1 ps-1 pe-2">fa-check-circle</v-icon>
                                            <v-icon v-if="!phone_pass_status" color="#FF3333" class="mt-n1 ps-1 pe-2">fa-times-circle</v-icon>-->
                                        </div>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item :ripple="false" class="px-0 border-radius-sm">
                                    <v-list-item-content v-model="email" class="py-0">
                                        <div class="text-body text-sm">
                                            <strong class="text-dark">電子郵件：</strong>
                                            {{email}}&nbsp;
                                            <span v-if="!email_pass_status" class="text-red  font-weight-bold ms-sm-2">
                                                (未驗證)
                                            </span>
                                            <span v-if="email_pass_status" class="text-green font-weight-bold ms-sm-2">
                                                (已驗證)
                                            </span>
                                            <v-btn v-if="!email_pass_status" class="
            font-weight-bolder
            btn-default
            bg-gradient-default
            ms-auto
            mt-sm-auto mt-4
          " small @click="verifyEmail">
                                                驗證
                                            </v-btn>
                                            <!--<v-btn :ripple="false" small text width="auto" color="#e91e63" class="font-weight-bolder py-4 px-5" @click="verifyEmail">
                                                <v-icon v-if="email_pass_status" color="#5FAF58" class="mt-n1 ps-1 pe-2">fa-check-circle</v-icon>
                                                <v-icon v-if="!email_pass_status" color="#FF3333" class="mt-n1 ps-1 pe-2">fa-times-circle</v-icon>
                                            </v-btn>-->
                                        </div>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item :ripple="false" class="px-0 border-radius-sm">
                                    <v-list-item-content v-model="birthday" class="py-0">
                                        <div class="text-body text-sm">
                                            <strong class="text-dark">生日：</strong>
                                            {{birthday}}
                                        </div>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item :ripple="false" class="px-0 border-radius-sm" v-if="is_member">
                                    <v-list-item-content v-model="identity" class="py-0">
                                        <div class="text-body text-sm">
                                            <strong class="text-dark">身份字號/統一編號：</strong>
                                            {{identity}}
                                        </div>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item :ripple="false" class="px-0 border-radius-sm" v-if="is_member">
                                    <v-list-item-content v-model="due_date" class="py-0">
                                        <div class="text-body text-sm">
                                            <strong class="text-dark">到期日：</strong>
                                            {{due_date}}
                                        </div>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item :ripple="false" class="px-0 border-radius-sm" v-if="is_member">
                                    <v-list-item-content v-model="bank_code" class="py-0">
                                        <div class="text-body text-sm">
                                            <strong class="text-dark">銀行代碼：</strong>
                                            {{bank_code}}
                                        </div>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item :ripple="false" class="px-0 border-radius-sm" v-if="is_member">
                                    <v-list-item-content v-model="bank_account" class="py-0">
                                        <div class="text-body text-sm">
                                            <strong class="text-dark">銀行帳戶：</strong>
                                            {{bank_account}}
                                        </div>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item :ripple="false" class="px-0 border-radius-sm" v-if="is_member">
                                    <v-list-item-content v-model="address" class="py-0">
                                        <div class="text-body text-sm">
                                            <strong class="text-dark">通訊地址：</strong>
                                            {{address}}
                                        </div>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item :ripple="false" class="px-0 border-radius-sm" v-if="is_member">
                                    <v-list-item-content v-model="parent_name" class="py-0">
                                        <div class="text-body text-sm">
                                            <strong class="text-dark">推薦人：</strong>
                                            {{parent_name}}
                                        </div>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </div>
                </v-card>
                <hr class="vertical dark" />
            </v-col>
            <v-col lg="7" cols="auto">
                <v-card class="h-100">
                    <div class="px-4 pt-4">
                        <h6 class="mb-0 text-h6 text-typo">推廣</h6>
                    </div>
                    <div class="px-4 py-4">
                        <v-list>
                            <v-list-item-group class="border-radius-sm">
                                <v-list-item :ripple="false" v-for="(value, index) in conversations" :key="value.text" class="px-0 border-radius-sm mb-2">
                                    <v-list-item-content class="pt-0">
                                        <div class="d-flex align-center">
                                            <div>
                                                <h6 class="
                            mb-0
                            text-sm text-sm text-typo
                            font-weight-bold
                          ">
                                                    {{ value.user }}
                                                </h6>
                                                <p ref="url" class="mb-0 text-xs text-body font-weight-light">
                                                    {{ value.message }}
                                                </p>
                                            </div>
                                            <div>
                                                <v-btn :ripple="false" small text width="auto" color="#e91e63" class="font-weight-bolder py-4 px-5" @click="copyUrl(index)">
                                                    <v-icon class="mt-n1 ps-1 pe-2">fa-clone</v-icon>
                                                </v-btn>
                                            </div>
                                        </div>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </div>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";
import Cookie from 'js-cookie';
import axios from 'axios';

let config = {
    headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
        "Content-Type": "application/json;",
    }
}

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

Vue.use(VueSweetalert2);

export default {
    name: "Profile-Overview",
    data: function() {
        return {
            userId: 0,
            name: "",
            phone: "",
            email: "",
            birthday: "",
            address: "",
            bank_code: "",
            bank_account: "",
            role: "",
            is_member: 1,
            email_pass_status: 0,
            phone_pass_status: 0,
            level: "",
            due_date: "",
            identity: "",
            signup_link: "",
            promote_link: "",
            parent_name: "",
            accountSettings: [{
                    switchState: true,
                    text: "Email me when someone follows me",
                },
                {
                    switchState: false,
                    text: "Email me when someone answers on...",
                },
                {
                    switchState: true,
                    text: "Email me when someone mentions me...",
                },
            ],
            applicationSettings: [{
                    switchState: false,
                    text: "New launches and projects",
                },
                {
                    switchState: true,
                    text: "Monthly product updates",
                },
                {
                    switchState: false,
                    text: "Subscribe to newsletter",
                },
            ],
            conversations: [
                /*
                {
                    avatar: require("@/assets/img/kal-visuals-square.jpg"),
                    user: "推廣註冊連結",
                    message: "",
                },
                */
                {
                    avatar: require("@/assets/img/marie.jpg"),
                    user: "導購推廣連結",
                    message: "",
                },

            ],
            cards: [{
                    image: require("@/assets/img/home-decor-1.jpg"),
                    title: "Project #2",
                    style: "Modern",
                    description: "As Uber works through a huge amount of internal management turmoil.",
                    avatars: [{
                            image: require("@/assets/img/team-1.jpg"),
                            name: "Elena Morison",
                        },
                        {
                            image: require("@/assets/img/team-2.jpg"),
                            name: "Ryan Milly",
                        },
                        {
                            image: require("@/assets/img/team-3.jpg"),
                            name: "Nick Daniel",
                        },
                        {
                            image: require("@/assets/img/team-4.jpg"),
                            name: "Peterson",
                        },
                    ],
                },
                {
                    image: require("@/assets/img/home-decor-2.jpg"),
                    title: "Project #1",
                    style: "Scandinavian",
                    description: "Music is something that every person has his or her own specific opinion about.",
                    avatars: [{
                            image: require("@/assets/img/team-3.jpg"),
                            name: "Nick Daniel",
                        },
                        {
                            image: require("@/assets/img/team-4.jpg"),
                            name: "Peterson",
                        },
                        {
                            image: require("@/assets/img/team-1.jpg"),
                            name: "Elena Morison",
                        },
                        {
                            image: require("@/assets/img/team-2.jpg"),
                            name: "Ryan Milly",
                        },
                    ],
                },
                {
                    image: require("@/assets/img/home-decor-3.jpg"),
                    title: "Project #3",
                    style: "Minimalist",
                    description: "Different people have different taste, and various types of music.",
                    avatars: [{
                            image: require("@/assets/img/team-4.jpg"),
                            name: "Peterson",
                        },
                        {
                            image: require("@/assets/img/team-3.jpg"),
                            name: "Nick Daniel",
                        },
                        {
                            image: require("@/assets/img/team-2.jpg"),
                            name: "Ryan Milly",
                        },
                        {
                            image: require("@/assets/img/team-1.jpg"),
                            name: "Elena Morison",
                        },
                    ],
                },
                {
                    image: require("@/assets/img/home-decor-4.jpg"),
                    title: "Project #4",
                    style: "Gothic",
                    description: "Why would anyone pick blue over pink? Pink is obviously a better color.",
                    avatars: [{
                            image: require("@/assets/img/team-4.jpg"),
                            name: "Peterson",
                        },
                        {
                            image: require("@/assets/img/team-3.jpg"),
                            name: "Nick Daniel",
                        },
                        {
                            image: require("@/assets/img/team-2.jpg"),
                            name: "Ryan Milly",
                        },
                        {
                            image: require("@/assets/img/team-1.jpg"),
                            name: "Elena Morison",
                        },
                    ],
                },
            ],
        };
    },
    mounted() {
        const token = Cookie.get('auth')

        console.log(token)
        console.log(typeof(token))
        console.log((token === 'null') || (typeof(token) === "undefined") )
        if ((token === 'null') || (typeof(token) === "undefined")) {
            this.$router.push('/pages/authentication/signup/login');
        }

        const tokenParts = token.split('.')
        console.log(tokenParts)
        const body = JSON.parse(atob(tokenParts[1]))
        console.log(body)
        console.log(body.sub)

        this.userId = body.sub

        let resp = axios.get('/v1/user/' + this.userId.toString(), this.config)
            .then(response => {
                this.repsData = response.data;
                console.log(this.repsData)

                this.name = this.repsData.name
                this.phone = this.repsData.phone
                this.email = this.repsData.email
                this.birthday = this.repsData.birthday
                this.address = this.repsData.address
                if (this.repsData.bank_code === 0) {
                    this.bank_code = ""
                } else {
                    this.bank_code = this.repsData.bank_code
                }

                if (this.repsData.bank_account === 0) {
                    this.bank_account = ""
                } else {
                    this.bank_account = this.repsData.bank_account
                }

                this.role = this.repsData.role
                this.level = this.repsData.level
                this.due_date = this.repsData.due_date
                this.identity = this.repsData.identity
                this.conversations[0].message = this.repsData.promote_link
                //this.conversations[1].message = this.repsData.signup_link
                this.email_pass_status = this.repsData.email_pass_status
                this.phone_pass_status = this.repsData.phone_pass_status
                this.parent_name = this.repsData.parent_name

                if (this.role == "經銷商" || this.role == "團購主") {
                    console.log(this.role)
                    this.is_member = 1
                } else {
                    console.log(this.role)
                    this.is_member = 0
                }

            })
            .catch(error => { // 请求失败处理
                console.log(error);
            });



    },
    methods: {
        copyUrl(index) {
            this.$swal("", "已複製連結。", "success");
            console.log(this.$refs)

            const storage = document.createElement('textarea');
            console.log(storage)
            storage.textContent = this.$refs.url[index].innerHTML;
            console.log(storage.textContent)

            document.body.append(storage)
            storage.select();
            storage.setSelectionRange(0, 99999);
            document.execCommand('copy');

        },
        verifyEmail() {
            this.$swal("", "已傳送驗證連結至電子郵件", "success");

            let resp = axios.post('/v1/verify/email/' + this.userId.toString(), this.config)
                .then(response => {
                    this.repsData = response.data;
                    console.log(this.repsData)

                })
                .catch(error => { // 请求失败处理
                    console.log(error);
                });

        },

        verifyPhone() {


            let resp = axios.post('/v1/verify/phone/' + this.userId.toString(), this.config)
                .then(response => {
                    this.repsData = response.data;
                    console.log(this.repsData)
                    const errorCode = response.data.error_code
                    const message = response.data.message

                    if (errorCode === '') {
                        this.$swal("", "已傳送驗證連結至行動電話", "success");
                    } else {
                        this.$swal("", message, "error");
                    }


                })
                .catch(error => { // 请求失败处理
                    console.log(error);
                });

        }



    },
};
</script>