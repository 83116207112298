<template>
    <v-row class="align-center d-flex h-100 mt-md-n16">
        <v-col lg="4" md="7" class="mx-auto">
            <v-card class="card-shadow border-radius-xl py-1">
                <div class="
                align-center
            card-padding
            text-center
            mt-n7
            bg-gradient-primary
            shadow-primary
            border-radius-lg
            mx-4
          ">
                    <h4 class="text-h4 font-weight-bolder text-white mb-2">
                        會員登入
                    </h4>
                    <p class="mb-1 text-white font-weight-light text-sm">
                        輸入您的帳號及密碼進行登入
                    </p>
                </div>
                <div class="text-center">
                    <v-progress-circular indeterminate :size="50" :width="5" color="blue" v-if="isShowProgress"></v-progress-circular>
                    <v-snackbar :value="alertSuccess" v-model="alertSuccess" min-height="100px" color="blue" content-class="text text-h4 mb-4" :timeout="timeout" absolute centered centered elevation="24">{{repsMessage}}</v-snackbar>
                    <v-snackbar :value="alertError" v-model="alertError" min-height="100px" color="blue" content-class="text text-h4 mb-4" :timeout="timeout" absolute centered centered elevation="24">{{repsMessage}}</v-snackbar>
                </div>
                <div class="card-padding text-center">
                    <v-text-field v-model="phoneOrEmail" @keydown.enter="postLogin" label="電話或電子郵件" color="#e91e63" outlined dense hide-details class="font-size-input input-style mb-5"></v-text-field>
                    <v-text-field v-model="password" @keydown.enter="postLogin" :value="myPass" label="密碼" :append-icon="value ? 'fa-eye-slash' : 'fa-eye'" @click:append="() => (value = !value)" :type="value ? 'password' : 'text'" color="#e91e63" outlined dense hide-details class="font-size-input input-style mb-5"></v-text-field>
                    <v-btn elevation="0" :ripple="false" height="43" class="
              font-weight-bold
              text-uppercase
              btn-default
              bg-gradient-primary
              py-2
              px-6
              me-2
              mt-6
              mb-2
              w-100
            " color="#5e72e4" small @click="postLogin">登入</v-btn>
                    <p class="text-body font-weight-light mt-3 mb-0">
                        <v-row align="center" justify="center" class="text-center">
                            <v-col align="center" justify="center" class="text-center" cols="4">
                                <a cols="6" href="javascript:;" class="text-primary text-decoration-none font-weight-bolder" @click="register">註冊新帳戶</a>
                            </v-col>
                            <v-spacer />
                            <v-col align="center" justify="center" class="text-center" cols="4">
                                <a cols="6" href="javascript:;" class="text-primary text-decoration-none font-weight-bolder" @click="forgetPassword">忘記密碼?</a>
                            </v-col>
                        </v-row>
                    </p>
                </div>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
import Cookie from 'js-cookie';
import axios from 'axios';

let config = {
    headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
        "Content-Type": "application/json;",
    }
}

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

const Base64 = require("js-base64").Base64;

export default {
    name: "sign-up-basic",
    data() {
        return {
            value: String,
            checkbox: false,
            timeout: 1000,
            phoneOrEmail: "",
            password: "",
            auth: "",
            userId: 0,
            alertSuccess: false,
            alertError: false,
            repsMessage: "",


        };
    },
    mounted() {
        const token = Cookie.get('auth')

        console.log(token)
        console.log(typeof(token))
        console.log((token === 'null') || (typeof(token) === "undefined") )
        if ((token === 'null') || (typeof(token) === "undefined")) {
            this.$router.push('/pages/authentication/signup/login');

        } else {
            const tokenParts = token.split('.')
            console.log(tokenParts)
            const body = JSON.parse(atob(tokenParts[1]))
            console.log(body)
            console.log(body.sub)

            axios.post('/v1/fast-login', {
                    user_id: body.sub,
                }, this.config)
                .then(response => {
                    console.log(response)
                    this.repsMessage = response.data.message
                    const errorCode = response.data.error_code
                    console.log(this.repsMessage)
                    console.log(errorCode)

                    if (errorCode === '') {
                        this.auth = response.data.token;
                        this.$store.commit('setAuth', this.auth);
                        Cookie.set('auth', null)
                        Cookie.set('auth', this.auth);
                        console.log(this.auth);

                        var tokenParts = this.auth.split('.');
                        var body = JSON.parse(atob(tokenParts[1]));
                        console.log("token body");
                        console.log(body);
                        console.log(body.sub);

                        this.userId = body.sub
                        console.log(this.userId)

                        setTimeout(() => {
                            this.$router.push('/pages/pages/profile/overview');
                        }, this.timeout)
                    }
                })
                .catch(function(error) { // 请求失败处理
                    console.log(error);
                });
        }
    },
    methods: {
        postLogin() {
            const resp = axios.post('/v1/login', {
                    email: this.phoneOrEmail,
                    password: this.password,
                }, this.config)
                .then(response => {
                    console.log(response)
                    this.repsMessage = response.data.message
                    const errorCode = response.data.error_code
                    console.log(this.repsMessage)
                    console.log(errorCode)

                    if (errorCode === '') {
                        this.alertSuccess = true
                        this.auth = response.data.token;
                        this.$store.commit('setAuth', this.auth);
                        Cookie.set('auth', this.auth);
                        console.log(this.auth);

                        var tokenParts = this.auth.split('.');
                        var body = JSON.parse(atob(tokenParts[1]));
                        console.log("token body");
                        console.log(body);
                        console.log(body.sub);

                        this.userId = body.sub
                        console.log(this.userId)

                        setTimeout(() => {
                            this.$router.push('/pages/pages/profile/overview');
                        }, this.timeout)

                    } else {
                        this.alertError = true
                    }

                })
                .catch(function(error) { // 请求失败处理
                    console.log(error);
                });

        },
        register() {
            setTimeout(() => {
                this.$router.push('/pages/authentication/signup/cover');
            }, this.timeout)
        },
        forgetPassword() {
            setTimeout(() => {
                this.$router.push('/pages/authentication/signup/forget-password');
            }, this.timeout)
        },

    },
};
</script>